@media print {
  div.avoidbreak {
    break-inside: avoid;
  }
  div.pagebreak { page-break-before: always; }
  .App-body {
	margin-top: 0em !important;
  }
}
.modal {
  z-index:9999;
}
 .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select files';
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.custom-file-input-button::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input-button::before {
  content: 'Add';
  display: inline-block;
  background: #198754;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  color:white;
  width:4em
}
.custom-file-input-button:hover::before {
  border-color: black;
}
.custom-file-input-button:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


 .custom-folder-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-folder-input::before {
  content: 'Select a folder';
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-folder-input:hover::before {
  border-color: black;
}
.custom-folder-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.App-books .nav-tabs .nav-item button {
  font-size:1.6em !important;
  font-weight: 500 !important;
}
#chords svg {
  float:left
}
button.track-remove {
  background-image: url('/public/close.png') ;
  background-size: cover;
}

button.track-collapse {
  background-image: url('/public/arrow-up.png') ;
  background-size: cover;
}
/*
 * // avoid controls over top of panel but this breaks cursor TODO ??
div.channel {
  left:200px !important;
}
*/

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}

.dropdown-item.even {
  background-color: #a8e1e142;
} 

.dropdown-item.odd {
  background-color: #d6efef;
} 

@media print {
 .App-header, #tunesearchextras, #footer, .music-buttons, .abcprogressslider, #abcplaybutton , #abcrecordbutton {
    display: none;
  }
  #footer div, #footer a, line.abcjs-cursor {
    display: none;
  }
  g.color {
   stroke: black !important;
   fill: black !important;
  }
/*
  .abcjs-container, .abcjs-unaligned-words {
     page-break-after: always;
     page-break-inside: avoid;
  }
*/
  
}

  .abcjs-unaligned-words {
     page-break-before: always !important ;
  }
  .abcjs-container svg {
    page-break-after: always !important ;
  }

.App-header {
  background-color: #2e00ff;
  position: fixed;
  top: 0;
  left: 0;
  height: 3.3em;
  width: 100%; 
  padding: 0.2em;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.App-body {
  margin-top: 5em;
/*
  margin-bottom: 3.6em;
*/
  padding: 0.2em;
  padding-left: 0.1em;
/*
  padding-bottom: 4em;
*/
  
}
input[type=range] {
  height: 27px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #3071A9;
  border-radius: 9px;
  border: 0px solid #FCFCFC;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 49px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #3071A9;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #3071A9;
  border-radius: 9px;
  border: 0px solid #FCFCFC;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 49px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #3071A9;
  border: 0px solid #FCFCFC;
  border-radius: 18px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #3071A9;
  border: 0px solid #FCFCFC;
  border-radius: 18px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 49px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071A9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #3071A9;
}

.parserdiff { 
max-width: 90% !important ;
}


.youtube-help-content img {
   width: 50%;
}

.index-layout {
  margin-top: 1em;
}
button.btn-primary {
  margin-left: 0.1em;
}
.list-group-item a {
  text-decoration: none;
}

.list-group .even {
  background-color: #a8e1e142;
}
.list-group .odd {
  background-color: #d6efef;
}
#tunebookbuttons button {
  margin-left:0.3em
}
#warnings {
  color: red;
}



/* Some basic CSS to make the Audio controls in abcjs presentable. */

.abcjs-inline-audio {
	height: 40px;
	padding: 0 5px;
	border-radius: 3px;
	background-color: #424242;
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

.abcjs-inline-audio.abcjs-disabled {
	opacity: 0.5;
}

.abcjs-inline-audio .abcjs-btn {
	display: block;
	width: 28px;
	height: 34px;
	margin-right: 2px;
	padding: 7px 4px;

	background: none !important;
	border: 1px solid transparent;
	box-sizing: border-box;
}

.abcjs-btn g {
	fill: #f4f4f4;
	stroke: #f4f4f4;
}

.abcjs-inline-audio .abcjs-btn:hover g {
	fill: #cccccc;
	stroke: #cccccc;
}

.abcjs-inline-audio .abcjs-midi-selection.abcjs-pushed {
	border: 1px solid #cccccc;
	background-color: #666666;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-midi-loop.abcjs-pushed {
	border: 1px solid #cccccc;
	background-color: #666666;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-midi-reset.abcjs-pushed {
	border: 1px solid #cccccc;
	background-color: #666666;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-midi-start .abcjs-pause-svg {
	display: none;
}

.abcjs-inline-audio .abcjs-midi-start .abcjs-loading-svg {
	display: none;
}

.abcjs-inline-audio .abcjs-midi-start.abcjs-pushed .abcjs-play-svg {
	display: none;
}

.abcjs-inline-audio .abcjs-midi-start.abcjs-loading .abcjs-play-svg {
	display: none;
}

.abcjs-inline-audio .abcjs-midi-start.abcjs-pushed .abcjs-pause-svg {
	display: block;
}

.abcjs-inline-audio .abcjs-midi-progress-background {
	background-color: #424242;
	height: 10px;
	border-radius: 5px;
	border: 2px solid #cccccc;
	margin: 0 8px 0 15px;
	position: relative;
	flex: 1;
	padding: 0;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-midi-progress-indicator {
	width: 20px;
	margin-left: -10px; /* half of the width */
	height: 14px;
	background-color: #f4f4f4;
	position: absolute;
	display: inline-block;
	border-radius: 6px;
	top: -4px;
	left: 0;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-midi-clock {
	margin-left: 4px;
	margin-top: 1px;
	margin-right: 2px;
	display: inline-block;
	font-family: sans-serif;
	font-size: 16px;
	box-sizing: border-box;
	color: #f4f4f4;
}

.abcjs-inline-audio .abcjs-tempo-wrapper {
	font-size: 10px;
	color: #f4f4f4;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.abcjs-inline-audio .abcjs-midi-tempo {
	border-radius: 2px;
	border: none;
	margin: 0 2px 0 4px;
	width: 42px;
	padding-left: 2px;
	box-sizing: border-box;
}

.abcjs-inline-audio .abcjs-loading .abcjs-loading-svg {
	display: inherit;
}

.abcjs-inline-audio .abcjs-loading {
	outline: none;
	animation-name: abcjs-spin;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

}
.abcjs-inline-audio .abcjs-loading-svg circle {
	stroke: #f4f4f4;
}

@keyframes abcjs-spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

/* Adding the class "abcjs-large" will make the control easier on a touch device. */
.abcjs-large .abcjs-inline-audio {
	height: 52px;
}
.abcjs-large .abcjs-btn {
	width: 56px;
	height: 52px;
	font-size: 28px;
	padding: 6px 8px;
}
.abcjs-large .abcjs-midi-progress-background {
	height: 20px;
	border: 4px solid #cccccc;
}
.abcjs-large .abcjs-midi-progress-indicator {
	height: 28px;
	top: -8px;
	width: 40px;
}
.abcjs-large .abcjs-midi-clock {
	font-size: 32px;
	margin-right: 10px;
	margin-left: 10px;
	margin-top: -1px;
}
.abcjs-large .abcjs-midi-tempo {
	font-size: 20px;
	width: 50px;
}
.abcjs-large .abcjs-tempo-wrapper {
	font-size: 20px;
}

.abcjs-css-warning {
	display: none;
}

.abcjs-cursor {
      stroke: blue;
      strokie-width:3px
   }
/*
 @media (prefers-color-scheme: dark) {
     .abcjs-cursor {
         stroke: #ffffff;
     }
 }
*/
 path {
 transition: opacity 1s;
}
.abcjs-note.hidden, .abcjs-beam-elem.hidden {
 opacity: 0;
}
.color {
 stroke: red;
 fill: red;
}






.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}





:root {
  --wp-controls-border-color: #000;
  --wp-controls-background-color: #fff;
  --wp-mute-solo-color: #343a40;
  --wp-mute-solo-hover-color: #fff;
  --wp-mute-solo-focus-color: rgba(52, 58, 64, 0.5);
  --wp-btn-danger-background-hover-color: #c82333;
  --wp-btn-danger-border-hover-color: #bd2130;
  --wp-btn-danger-background-color: #dc3545;
  --wp-btn-danger-border-color: #dc3545;
  --wp-btn-danger-focus-color: rgba(225, 83, 97, 0.5);
  --wp-btn-danger-color: #fff;
  --wp-btn-info-background-hover-color: #138496;
  --wp-btn-info-border-hover-color: #117a8b;
  --wp-btn-info-background-color: #17a2b8;
  --wp-btn-info-border-color: #17a2b8;
  --wp-btn-info-focus-color: rgba(58, 176, 195, 0.5);
  --wp-btn-info-color: #fff;
  --wp-fade-background-color: rgba(0, 0, 0, 0.1);
  --wp-channel-color: #FFD500;
  --wp-channel-progress-color: #fbbc04;
  --wp-cursor-color: black;
  --wp-selection-background-color: rgba(0, 0, 0, 0.1);
  --wp-selection-point-color: red;
  --wp-tracks-container-background-color: #005BBB;
  --wp-range-slider-label-color: black;
  --wp-range-slider-color: goldenrod;
  --wp-range-slider-focus-color: black;
  --wp-range-slider-background-color: #ddd;
  --wp-range-slider-background-focus-color: #bbb;
  --wp-annotation-box-border-color: grey;
  --wp-annotation-current-background-color: #ebf4f6;
  --wp-annotation-icon-hover-color: orange;
}

.playlist {
  margin: 2em 0;
}
.playlist *, .playlist ::after, .playlist ::before {
  box-sizing: border-box;
}
.playlist .btn:not(:disabled) {
  cursor: pointer;
}
.playlist .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.playlist .btn-outline-dark {
  color: var(--wp-mute-solo-color);
  border-color: var(--wp-mute-solo-color);
}
.playlist .btn-outline-dark:hover {
  color: var(--wp-mute-solo-hover-color);
  background-color: var(--wp-mute-solo-color);
  border-color: var(--wp-mute-solo-color);
}
.playlist .btn-outline-dark:active:focus, .playlist .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem var(--wp-mute-solo-focus-color);
}
.playlist .btn-danger {
  color: var(--wp-btn-danger-color);
  background-color: var(--wp-btn-danger-background-color);
  border-color: var(--wp-btn-danger-border-color);
}
.playlist .btn-danger:hover {
  color: var(--wp-btn-danger-color);
  background-color: var(--wp-btn-danger-background-hover-color);
  border-color: var(--wp-btn-danger-border-hover-color);
}
.playlist .btn-danger:active:focus, .playlist .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem var(--wp-btn-danger-focus-color);
}
.playlist .btn-info {
  color: var(--wp-btn-info-color);
  background-color: var(--wp-btn-info-background-color);
  border-color: var(--wp-btn-info-border-color);
}
.playlist .btn-info:hover {
  color: var(--wp-btn-info-color);
  background-color: var(--wp-btn-info-background-hover-color);
  border-color: var(--wp-btn-info-border-hover-color);
}
.playlist .btn-info:active:focus, .playlist .btn-info:focus {
  box-shadow: 0 0 0 0.2rem var(--wp-btn-info-focus-color);
}
.playlist .btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}
.playlist .btn-group {
  margin-bottom: 0.3rem;
}
.playlist .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.playlist .btn-group > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.playlist .playlist-time-scale {
  height: 30px;
}
.playlist .playlist-tracks {
  background: var(--wp-tracks-container-background-color);
}
.playlist .channel {
  background: var(--wp-channel-color);
}
.playlist .channel-progress {
  background: var(--wp-channel-progress-color);
}
.playlist .cursor {
  background: var(--wp-cursor-color);
}
.playlist .wp-fade {
  background-color: var(--wp-fade-background-color);
}
.playlist .state-cursor,
.playlist .state-select {
  cursor: text;
}
.playlist .state-fadein {
  cursor: w-resize;
}
.playlist .state-fadeout {
  cursor: e-resize;
}
.playlist .state-shift {
  cursor: ew-resize;
}
.playlist .selection.point {
  background: var(--wp-selection-point-color);
}
.playlist .selection.segment {
  background: var(--wp-selection-background-color);
}
.playlist .channel-wrapper.silent .channel {
  opacity: 0.3;
}
.playlist .controls {
  background: var(--wp-controls-background-color);
  text-align: center;
  border: 1px solid var(--wp-controls-border-color);
  border-radius: 0.2rem;
}
.playlist .controls .track-header {
  overflow: hidden;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2rem;
  font-size: 0.75rem;
}
.playlist .controls .track-header button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playlist .controls label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  padding: 0 1rem;
  margin-bottom: 0.2rem;
}
.playlist .controls label:before {
  color: var(--wp-range-slider-label-color);
  font-size: 18px;
  padding-right: 5px;
  -moz-osx-font-smoothing: grayscale;
}
.playlist .controls label:after {
  color: var(--wp-range-slider-label-color);
  font-size: 18px;
  padding-left: 5px;
}
.playlist .controls label.volume:before {
  content: "\f027";
}
.playlist .controls label.volume:after {
  content: "\f028";
}
.playlist .controls label.stereopan:before {
  content: "L";
}
.playlist .controls label.stereopan:after {
  content: "R";
}
.playlist .controls input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 75%;
}
.playlist .controls input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  background: var(--wp-range-slider-background-color);
  border: none;
  border-radius: 3px;
}
.playlist .controls input[type=range]::-moz-range-track {
  height: 5px;
  background: var(--wp-range-slider-background-color);
  border: none;
  border-radius: 3px;
}
.playlist .controls input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--wp-range-slider-color);
  margin-top: -5px;
  cursor: ew-resize;
}
.playlist .controls input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--wp-range-slider-color);
  margin-top: -5px;
  cursor: ew-resize;
}
.playlist .controls input[type=range]:focus {
  outline: none;
}
.playlist .controls input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--wp-range-slider-background-focus-color);
}
.playlist .controls input[type=range]:focus::-moz-range-track {
  background: var(--wp-range-slider-background-focus-color);
}
.playlist .controls input[type=range]:focus::-webkit-slider-thumb {
  border: 2px solid var(--wp-range-slider-focus-color);
}
.playlist .controls input[type=range]:focus::-moz-range-thumb {
  border: 2px solid var(--wp-range-slider-focus-color);
}
.playlist .annotations .annotations-boxes {
  text-align: center;
}
.playlist .annotations .annotation-box {
  border: 2px dashed var(--wp-annotation-box-border-color);
  padding: 0 10px;
  line-height: 1.5;
}
.playlist .annotations .annotation-box .resize-handle {
  background: var(--wp-annotation-box-border-color);
  opacity: 0.3;
  cursor: ew-resize;
}
.playlist .annotations .annotation-box .id {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.playlist .annotations .annotations-text {
  font-size: 19px;
  font-weight: 300;
  margin-top: 1em;
  height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
}
.playlist .annotations .annotations-text .annotation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.playlist .annotations .annotations-text .annotation span {
  margin: 0.3rem 0.6rem;
}
.playlist .annotations .annotations-text .annotation span:last-of-type {
  margin-right: 1.2rem;
}
.playlist .annotations .annotations-text .annotation .annotation-id {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-start {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-end {
  font-size: 16px;
  line-height: 27px;
}
.playlist .annotations .annotations-text .annotation .annotation-lines {
  flex-grow: 10;
}
.playlist .annotations .annotations-text .annotation .annotation-actions {
  flex-basis: auto;
  width: 85px;
  text-align: right;
  font-size: 16px;
}
.playlist .annotations .annotations-text .annotation .annotation-actions i {
  margin-right: 0.6rem;
}
.playlist .annotations .annotations-text .annotation .annotation-actions i:last-of-type {
  margin-right: 0;
}
.playlist .annotations .annotations-text .annotation .annotation-actions i:hover {
  color: var(--wp-annotation-icon-hover-color);
  cursor: pointer;
}
.playlist .annotations .current {
  background-color: var(--wp-annotation-current-background-color);
}

a {
  color: #1d70b8;
  cursor: pointer;
  text-decoration: underline;
}

a:link {
  color: #1d70b8;
}

a:visited {
  color: #4c2c92;
}

a:hover {
  color: #003078;
}

a:active {
  color: #0b0c0c;
}

a:focus {
  color: #0b0c0c;
}

.breadcrumb {
  background-color: #f4f4f4;
}

.playlist .vocals {
  background-color: #c0dce0;
}

.track-drop {
  border: 2px dashed blue;
  height: 100px;
  width: 200px;
  margin: 1em 0;
}
.track-drop::before {
  content: "Drop audio file(s) here!";
}
.track-drop.drag-enter {
  border: 2px solid orange;
}

footer {
  margin-top: 2em;
}

img {  
  position: relative;
}

/* style this to fit your needs */
/* and remove [alt] to apply to all images*/
button img:after {  
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d6efd;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;  
  text-align: center;
  content: attr(alt);
}
